html {
    width: 100%;
    min-height: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    min-height: 100%;

    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
    width: 100%;
    height: 100%;
}

/* Extras */
input {
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
input:focus {
    outline: none;
}

.eden:link {
    color: #5086ea;
    background-color: transparent;
    text-decoration: none;
}

.eden:visited {
    color: #5086ea;
    background-color: transparent;
    text-decoration: underline;
}

.eden:hover {
    color: #5086ea;
    background-color: transparent;
    text-decoration: underline;
}

.eden:active {
    color: #5086ea;
    background-color: transparent;
    text-decoration: underline;
}

.links:link {
    color: inherit;
    background-color: transparent;
    text-decoration: none;
}

.links:visited {
    color: inherit;
    background-color: transparent;
    text-decoration: none;
}

.links:hover {
    color: inherit;
    background-color: transparent;
    text-decoration: none;
}

.links:active {
    color: inherit;
    background-color: transparent;
    text-decoration: none;
}

.apexcharts-xaxis-texts-g .apex-custom-line-x-axis:first-child {
    transform: translateX(21px) !important;
}
.apexcharts-xaxis-texts-g .apex-custom-line-x-axis:last-child {
    /* display: none; */
    transform: translateX(-21px) !important;
}
.apexcharts-tooltip.dark {
    background: #191e3a !important;
    box-shadow: none;
}
.apexcharts-tooltip.dark .apexcharts-tooltip-title {
    background: #191e3a !important;
    border-bottom: 1px solid #191e3a;
}

.apexcharts-gridlines-vertical line:first-child {
    display: none !important;
}
.apexcharts-gridlines-vertical line:last-child {
    display: none !important;
}

.apexcharts-xaxis-tick {
    display: none !important;
}

.apexcharts-xcrosshairs {
    display: none !important;
}

.apexcharts-tooltip-marker {
    display: none !important;
}

.bold {
    font-weight: 700;
}

.ml-6 {
    margin-left: 6px;
}

.flex-center {
    display: 'flex';
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.error {
    color: #ff5666;
}
